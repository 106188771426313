import "core-js/modules/es6.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios';
import { getTokens } from '@/utils/auth';
export default {
  name: 'MyExportExcel',
  props: {
    filename: {
      type: String,
      required: true
    },
    list: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    labelArr: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    valueArr: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    // 微调数据 参考staff-list.vue
    fixData: {
      type: Function,
      default: function _default(key, val) {
        return val;
      }
    },
    screenE: {
      type: Object,
      required: true,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      label: this.labelArr,
      filterVal: this.valueArr,
      dialogTableVisible: false,
      downloadLoading: false,
      cids: [],
      type: null
    };
  },
  computed: {
    keyValueObj: function keyValueObj() {
      var _this = this;
      return this.labelArr.reduce(function (acc, cur, curI) {
        acc[cur] = _this.valueArr[curI];
        return acc;
      }, {});
    }
  },
  watch: {
    label: function label(newV) {
      var _this2 = this;
      this.filterVal = newV.map(function (e) {
        return _this2.keyValueObj[e];
      });
    }
  },
  methods: {
    showDialog: function showDialog() {
      this.cids = [];
      // if (this.list.length) {
      for (var i = 0; i < this.list.length; i++) {
        this.cids.push(this.list[i].customer_id);
      }
      this.dialogTableVisible = true;
      // } else {
      //     this.$message({
      //         message: '请选择需要导出的对象',
      //         type: 'warning'
      //     });
      // }
    },
    handleDownload: function handleDownload() {
      var _this3 = this;
      console.log(this.screenE);
      this.dialogTableVisible = false;
      this.downloadLoading = true;
      // if (this.cids != '') {
      axios.defaults.headers.common['token'] = getTokens();
      axios.defaults.headers.common['content-type'] = 'application/octet-stream,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      axios.post(process.env.VUE_APP_BASE_API2 + '/clientserver.php/ExtensionAnalysis/exportExcel', {
        stime: this.screenE.creationDateName[0],
        etime: this.screenE.creationDateName[1],
        popularize_platform_ids: this.screenE.advert_id.toString(),
        create_ids: this.screenE.PlatformId.toString(),
        operate_type: this.screenE.modes.toString(),
        field_list: this.filterVal.toString()
      }, {
        responseType: 'blob'
      }).then(function (res) {
        _this3.downloadExcel(res, '推广分析信息表.xlsx');
      });
      this.downloadLoading = false;
      // } else {
      //     this.$message('请选择客户!');
      // }
    },
    //下载方法
    downloadExcel: function downloadExcel(res) {
      var fileName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '未命名.xlsx';
      var a = document.createElement('a');
      var blob = new Blob([res.data], {
        type: 'application/vnd.ms-excel'
      });
      var url = URL.createObjectURL(blob);
      a.setAttribute('href', url);
      a.setAttribute('download', fileName);
      a.click();
    },
    formatJson: function formatJson(filterVal, jsonData) {
      var _this4 = this;
      return jsonData.map(function (v) {
        return filterVal.map(function (j) {
          return _this4.fixData(j, v[j]);
        });
      });
    }
  }
};