var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container Extension" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "auto" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { height: "32px" }, attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 8, lg: 8, xl: 6 } },
                      [_c("Head", { attrs: { name: _vm.title } })],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { staticClass: "btn", attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 4, sm: 4, md: 4, lg: 10, xl: 7 } },
                      [
                        _vm._v(
                          "\n                    日期：\n                    "
                        ),
                        _c("el-date-picker", {
                          staticStyle: { width: "352px !important" },
                          attrs: {
                            size: "small",
                            "popper-class": "currentDatePickerClass",
                            type: "datetimerange",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "  结束日期",
                            clearable: false,
                            "default-time": ["00:00:00", "23:59:59"],
                            "value-format": "timestamp",
                          },
                          on: { change: _vm.creatDateTime },
                          model: {
                            value: _vm.creationDateName,
                            callback: function ($$v) {
                              _vm.creationDateName = $$v
                            },
                            expression: "creationDateName",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 4, sm: 4, md: 4, lg: 10, xl: 4 } },
                      [
                        _vm._v(
                          "\n                    账户简称：\n                    "
                        ),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "140px" },
                            attrs: {
                              filterable: "",
                              multiple: "",
                              "collapse-tags": "",
                              placeholder: "请选择",
                              size: "small",
                              clearable: "",
                            },
                            on: {
                              change: _vm.AbbreviationClick,
                              focus: _vm.GetAllAccountAbbreviation,
                            },
                            model: {
                              value: _vm.screen.AbbreviationId,
                              callback: function ($$v) {
                                _vm.$set(_vm.screen, "AbbreviationId", $$v)
                              },
                              expression: "screen.AbbreviationId",
                            },
                          },
                          _vm._l(_vm.Abbreviation, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.account_abbreviation,
                                value: item.id,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 4, sm: 4, md: 4, lg: 10, xl: 4 } },
                      [
                        _vm._v(
                          "\n                    推广平台：\n                    "
                        ),
                        _c("el-cascader", {
                          staticStyle: { width: "140px" },
                          attrs: {
                            options: _vm.platformOptions,
                            size: "small",
                            clearable: "",
                            "collapse-tags": "",
                            props: {
                              value: "id",
                              label: "name",
                              multiple: true,
                            },
                            "show-all-levels": false,
                          },
                          on: {
                            focus: _vm.GetOneLevelPlatform,
                            change: _vm.advertIdChange,
                          },
                          model: {
                            value: _vm.screen.advert_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.screen, "advert_id", $$v)
                            },
                            expression: "screen.advert_id",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 4, sm: 4, md: 4, lg: 10, xl: 5 } },
                      [
                        _vm._v(
                          "\n                    数据创建人：\n                    "
                        ),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "140px" },
                            attrs: {
                              filterable: "",
                              multiple: "",
                              "collapse-tags": "",
                              placeholder: "请选择",
                              size: "small",
                              clearable: "",
                            },
                            on: {
                              change: _vm.sourceVal,
                              focus: _vm.GetAgentForPopularizePlatform,
                            },
                            model: {
                              value: _vm.screen.PlatformId,
                              callback: function ($$v) {
                                _vm.$set(_vm.screen, "PlatformId", $$v)
                              },
                              expression: "screen.PlatformId",
                            },
                          },
                          _vm._l(_vm.PlatformArr, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.realname, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { staticClass: "btn", attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 4, sm: 4, md: 4, lg: 2, xl: 1 } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.queryClick },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "margin-left": "25px" },
                        attrs: { xs: 4, sm: 4, md: 4, lg: 2, xl: 1 },
                      },
                      [
                        _c("my-export-excel", {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["导出"],
                              expression: "['导出']",
                            },
                          ],
                          staticClass: "btnse",
                          staticStyle: {
                            "padding-top": "0 !important",
                            "padding-left": "2px !important",
                            "padding-bottom": "0px !important",
                          },
                          attrs: {
                            filename: "导出",
                            screenE: _vm.screen,
                            list: _vm.multipleSelection,
                            "label-arr": _vm.displayOptions,
                            "value-arr": _vm.exportValueArr,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { float: "right" },
                        attrs: { xs: 4, sm: 4, md: 4, lg: 2, xl: 1 },
                      },
                      [
                        _c("display-settingser", {
                          staticClass: "ml10 filter-item",
                          staticStyle: { float: "right" },
                          attrs: {
                            "display-arr": _vm.displaySettingsWhole,
                            "display-value": _vm.displaySettings,
                          },
                          on: {
                            "update:displayValue": function ($event) {
                              _vm.displaySettings = $event
                            },
                            "update:display-value": function ($event) {
                              _vm.displaySettings = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.enterTimeShow,
                              expression: "enterTimeShow",
                            },
                          ],
                          staticStyle: { "margin-top": "15px" },
                        },
                        [
                          _c("span", { staticClass: "nameTitle" }, [
                            _vm._v("最后修改时间："),
                          ]),
                          _vm._v(" "),
                          _c("time-component", {
                            attrs: {
                              timevla: _vm.finalFollow,
                              titleT: "最后修改时间",
                            },
                            on: {
                              FollowClick: _vm.FollowClick,
                              forkClick: _vm.forkClick,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.OperationShow,
                              expression: "OperationShow",
                            },
                          ],
                          staticStyle: { "margin-top": "15px" },
                        },
                        [
                          _c("span", { staticClass: "nameTitle" }, [
                            _vm._v("运营方式："),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              attrs: { size: "small", placeholder: "请选择" },
                              on: { change: _vm.modesClick },
                              model: {
                                value: _vm.screen.modes,
                                callback: function ($$v) {
                                  _vm.$set(_vm.screen, "modes", $$v)
                                },
                                expression: "screen.modes",
                              },
                            },
                            _vm._l(_vm.ModesArr, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.label, value: item.id },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.Deletesshow("运营方式")
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      ref: "multipleTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        border: "",
                        id: "customerTable",
                        "header-cell-style": {
                          background: "#F9F9F9",
                          color: "#222222",
                        },
                        "summary-method": _vm.AccountDetails,
                        "show-summary": "",
                      },
                      on: {
                        "sort-change": _vm.sortEs,
                        "selection-change": _vm.selectRows,
                      },
                    },
                    [
                      _vm.displaySettings.includes("序号")
                        ? _c("el-table-column", {
                            key: 0,
                            attrs: {
                              label: "序号",
                              align: "center",
                              width: "50",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.$index +
                                              (_vm.query.page - 1) *
                                                _vm.query.pagesize +
                                              1
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3946342523
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("推广账户简称")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "推广账户简称",
                              prop: "account_abbreviation",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("推广平台")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "推广平台",
                              prop: "popularize_platform_name",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("运营方式")
                        ? _c(
                            "el-table-column",
                            {
                              key: Math.random(),
                              attrs: {
                                label: "运营方式",
                                prop: "operate_type",
                                align: "center",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("运营方式")
                                      },
                                    },
                                  },
                                  [_vm._v("运营方式")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("创建人")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "创建人",
                              prop: "create_user_name",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("展示")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "展示",
                              prop: "show",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("点击")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "点击",
                              prop: "click",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("点击率")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "点击率",
                              prop: "crt",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("消费")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "消费",
                              prop: "cost",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("返点")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "返点",
                              prop: "rebate",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(_vm._s(scope.row.rebate) + "%"),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1567800237
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("账户现金消费")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "账户现金消费",
                              prop: "cash_consumption",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("名片数")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "名片数",
                              prop: "visiting_card",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("名片成本")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "名片成本",
                              prop: "visiting_card_cost",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("名片获取率")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "名片获取率",
                              prop: "visiting_card_rate",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("录入量")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "录入量",
                              prop: "input",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("录入成本")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "录入成本",
                              prop: "input_cost",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("学员级别")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "学员级别",
                              prop: "customer_level",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { color: "#1890ff" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.LevelClick(
                                                scope.row.era_account
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(scope.row.customer_level)
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2588585843
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("数据分配")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "数据分配",
                              prop: "data_distribution",
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { color: "#1890ff" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.dataDistribution(
                                                scope.row.era_account
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.data_distribution
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2468970265
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("有效率")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "有效率",
                              prop: "valid_rate",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("撞库率")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "撞库率",
                              prop: "zhuangdan_rate",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("成交量")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "成交量",
                              prop: "deal_num",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("成交率")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "成交率",
                              prop: "success_rate",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("收入")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "收入",
                              prop: "income",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("消费费比")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "消费费比",
                              prop: "cost_income_rate",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("现金费比")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "现金费比",
                              prop: "cash_consumption_income_rate",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("账户余额")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "账户余额",
                              prop: "balance",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("ROI")
                        ? _c("el-table-column", {
                            key: Math.random(),
                            attrs: {
                              label: "ROI",
                              prop: "roi",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "tableLeft",
                      attrs: { id: "tableLeft" },
                      on: {
                        scroll: function ($event) {
                          return _vm.exterHandleScroll()
                        },
                      },
                    },
                    [
                      _c("div", {
                        staticStyle: { height: "10px" },
                        attrs: { id: "bodyLeft" },
                      }),
                    ]
                  ),
                ],
              ],
              2
            ),
            _vm._v(" "),
            _c("pagination", {
              attrs: {
                "current-page": _vm.query.page,
                totalPage: _vm.query.total,
                pageSize: _vm.query.pagesize,
              },
              on: {
                handleSizeChange: _vm.handleSizeChange,
                handleCurrentChange: _vm.handleCurrentChange,
              },
            }),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                staticClass: "Mtop",
                staticStyle: { margin: "0 auto" },
                attrs: {
                  title: "客户级别详情",
                  visible: _vm.dialogProject,
                  width: "800px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogProject = $event
                  },
                  close: _vm.cancel,
                },
              },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableDataLevel, border: "" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "name",
                        align: "center",
                        label: "客户级别",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "num", align: "center", label: "数量" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                staticClass: "Mtop",
                staticStyle: { margin: "0 auto" },
                attrs: {
                  title: "分配数据详情",
                  visible: _vm.dialogStatistics,
                  width: "800px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogStatistics = $event
                  },
                  close: _vm.cancel,
                },
              },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableDataStatistics, border: "" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "name",
                        align: "center",
                        label: "部门名称",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "num", align: "center", label: "数量" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }