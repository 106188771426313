import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getPlatform as _getPlatform, ExtensionAnalysisList, get_one_level_platform, get_all_account_abbreviation, get_all_create_user, PageIdCustomerLevelStatistics, pageIdDistributionStatistics } from '@/api/ceshi_xiugai/popularize';
import Head from '@/components/head/index';
import moment from 'moment';
import MyExportExcel from '@/components/MyExportExcel/index1';
import DisplaySettingser from '@/components/DisplaySettings/extension';
import TimeComponent from '@/components/TimeComponent/index';
export default {
  name: 'extensionProject',
  data: function data() {
    return {
      //系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      dialogProject: false,
      //系统教程
      title: '推广分析',
      // 列表请求参数
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      displaySettings: [],
      displaySettingsWhole: [],
      listLoading: false,
      enterTimeShow: false,
      OperationShow: false,
      dialogStatistics: false,
      tableData: [],
      enterTime: '',
      finalFollow: '',
      creationDateName: [],
      platformOptions: [],
      screen: {
        creationDateName: [],
        AbbreviationId: [],
        advert_id: [],
        PlatformId: [],
        modes: ''
      },
      Abbreviation: [],
      PlatformArr: [],
      stime: '',
      etime: '',
      consumption: 0,
      cards: 0,
      InputSe: 0,
      tableDataLevel: [],
      ModesArr: [{
        id: 1,
        label: '自运营'
      }, {
        id: 2,
        label: '代运营'
      }],
      tableDataStatistics: [],
      multipleSelection: [],
      //导出，客户信息
      displayOptions: ['序号', '推广账户简称', '推广平台', '运营方式', '创建人', '展示', '点击', '点击率', '消费', '返点', '账户现金消费', '名片数', '名片成本', '名片获取率', '录入量', '录入成本', '学员级别', '数据分配', '有效率', '撞库率', '成交量', '成交率', '收入', '消费费比', '现金费比', '账户余额', 'ROI'],
      //导出，标签文字
      exportValueArr: ['account_abbreviation', 'balance', 'cash_consumption', 'cash_consumption_income_rate', 'click', 'cost', 'cost_income_rate', 'create_user_name', 'crt', 'customer_level', 'data_distribution', 'deal_num', 'id', 'income', 'input', 'input_cost', 'operate_type', 'popularize_platform_name', 'rebate', 'roi', 'show', 'success_rate', 'valid_rate', 'visiting_card', 'visiting_card_cost', 'visiting_card_rate', 'zhuangdan_rate'] //导出，向后端传递的参数
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;
    if (this.course != 1) {
      this.created();
      this.getList(this.query.page, this.query.pagesize);
      var that = this;
      // 控制表格滚动条
      this.$nextTick(function () {
        var mutationObserver = new MutationObserver(function (mutations) {
          that.setHeight();
        });
        mutationObserver.observe(_this.$refs.multipleTable.bodyWrapper, {
          attributes: true,
          subtree: true
        });
      });
    } else {}
  },
  components: {
    Head: Head,
    TimeComponent: TimeComponent,
    DisplaySettingser: DisplaySettingser,
    MyExportExcel: MyExportExcel
  },
  methods: {
    created: function created() {
      var startTime = moment(moment().subtract(1, 'day').startOf('day').valueOf());
      var endTime = moment(moment().subtract(1, 'day').endOf('day').valueOf());
      var sta = new Date(startTime.format('YYYY-MM-DD 00:00:00'));
      var end = new Date(endTime.format('YYYY-MM-DD 23:59:59'));
      this.creationDateName.unshift(sta);
      this.creationDateName.push(end);
      this.stime = Date.parse(this.creationDateName[0]).toString().substring(0, 10);
      this.etime = Date.parse(this.creationDateName[1]).toString().substring(0, 10);
      this.screen.creationDateName.push(this.stime);
      this.screen.creationDateName.push(this.etime);
    },
    getPlatform: function getPlatform() {
      var _this2 = this;
      _getPlatform({
        type: 1
      }).then(function (respomse) {
        _this2.platformOptions = respomse.data;
      });
    },
    GetAllAccountAbbreviation: function GetAllAccountAbbreviation() {
      var _this3 = this;
      get_all_account_abbreviation().then(function (res) {
        _this3.Abbreviation = res.data;
      });
    },
    GetOneLevelPlatform: function GetOneLevelPlatform() {
      var _this4 = this;
      get_one_level_platform({
        type: 1
      }).then(function (res) {
        _this4.platformOptions = res.data;
      });
    },
    GetAgentForPopularizePlatform: function GetAgentForPopularizePlatform() {
      var _this5 = this;
      get_all_create_user().then(function (res) {
        _this5.PlatformArr = res.data;
      });
    },
    selectRows: function selectRows(val) {
      this.multipleSelection = val;
    },
    //点×
    Deletesshow: function Deletesshow(item) {
      this.query.page = 1;
      if (item === '运营方式') {
        this.OperationShow = false;
        this.screen.modes = '';
      }
      this.getList(this.query.page, this.query.pagesize);
    },
    modesClick: function modesClick() {},
    getList: function getList(page, pagesize) {
      var _this6 = this;
      //账号客户列表
      this.listLoading = true;
      ExtensionAnalysisList({
        page: String(page),
        pagesize: String(pagesize),
        stime: this.stime,
        etime: this.etime,
        operate_type: this.screen.modes,
        create_ids: this.screen.PlatformId.toString(),
        era_ids: this.screen.AbbreviationId.toString(),
        popularize_platform_ids: this.screen.advert_id.toString()
      }).then(function (respomse) {
        _this6.tableData = respomse.data.data;
        _this6.query.total = respomse.data.total;
        _this6.displaySettings = respomse.data.fields;
        _this6.listLoading = false;
        _this6.displaySettingsWhole = [];
        _this6.displayOptions = [];
        _this6.exportValueArr = [];
        for (var item in respomse.data.base_fields) {
          _this6.displaySettingsWhole.push(respomse.data.base_fields[item]);
          _this6.displayOptions.push(respomse.data.base_fields[item]);
          _this6.exportValueArr.push(item);
        }
      }).catch(function () {
        _this6.tableData = [];
        _this6.listLoading = false;
      });
    },
    creatDateTime: function creatDateTime() {
      if (this.creationDateName == null) {
        this.stime = '';
        this.etime = '';
      } else {
        this.screen.creationDateName = [];
        this.stime = this.creationDateName[0].toString().substring(0, 10);
        this.etime = this.creationDateName[1].toString().substring(0, 10);
        this.screen.creationDateName.push(this.stime);
        this.screen.creationDateName.push(this.etime);
      }
    },
    advertIdChange: function advertIdChange(val) {
      // const id = val.slice(-1);
      // this.advertIdChangeName = id.toString();;
      // this.getList(this.query.page,this.query.pagesize)
    },
    // 设置滚动条相关信息1111111111
    setHeight: function setHeight() {
      var widthValue = document.getElementById('customerTable').clientWidth + 'px';
      this.tableBodyWidth = document.getElementById('customerTable').childNodes[1].childNodes[0].clientWidth + 'px';
      var div = document.getElementById('tableLeft');
      div.style.width = widthValue;
      var div2 = document.getElementById('bodyLeft');
      div2.style.width = this.tableBodyWidth;
    },
    // 控制表格滚动条1111111
    exterHandleScroll: function exterHandleScroll() {
      var table = this.$refs.multipleTable.bodyWrapper;
      table.scrollLeft = this.$refs.tableLeft.scrollLeft;
    },
    //表头显示搜索框
    Activeshow: function Activeshow(item) {
      if (item === '最后修改时间') {
        this.enterTimeShow = true;
      } else if (item === '运营方式') {
        this.OperationShow = true;
      }
    },
    FollowClick: function FollowClick(start, end, title) {},
    forkClick: function forkClick(item) {},
    sortEs: function sortEs(column, prop, order) {
      // ascending 升序 、 descending降序 、 null 不排序
    },
    cancel: function cancel() {},
    submitEnterTime: function submitEnterTime(item) {},
    handleSizeChange: function handleSizeChange(val) {
      //每页 ${val} 条
      this.getList(this.query.page, val);
      this.query.pagesize = val;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      //当前页
      this.getList(val, this.query.pagesize);
      this.query.page = val;
    },
    getTimestamp: function getTimestamp(time) {
      //把时间日期转成时间戳
      return new Date(time).getTime() / 1000;
    },
    AccountDetails: function AccountDetails(param) {
      var _this7 = this;
      var columns = param.columns,
        data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 1) {
          sums[index] = '合计';
          return;
        } else if (index === 5 || index === 6 || index === 8 || index === 10 || index === 11 || index === 12 || index === 14 || index === 15 || index === 20 || index === 22 || index === 25) {
          var values = data.map(function (item) {
            return Number(item[column.property]);
          });
          if (!values.every(function (value) {
            return isNaN(value);
          })) {
            sums[index] = values.reduce(function (prev, curr) {
              var value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = sums[index].toFixed(2);
            if (index === 8) {
              _this7.consumption = sums[index];
            }
            if (index === 11) {
              _this7.cards = sums[index];
            }
            if (index === 12) {
              sums[index] = String(Number(_this7.consumption) / Number(_this7.cards)).match(/^\d+(?:\.\d{0,2})?/);
            }
            if (index === 14) {
              _this7.InputSe = sums[index];
            }
            if (index === 15) {
              sums[index] = String(Number(_this7.consumption) / Number(_this7.InputSe)).match(/^\d+(?:\.\d{0,2})?/);
            }
          } else {
            sums[index] = '--';
          }
        }
      });
      return sums;
    },
    queryClick: function queryClick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    sourceVal: function sourceVal(val) {
      console.log(val);
    },
    AbbreviationClick: function AbbreviationClick(val) {
      console.log(val);
    },
    LevelClick: function LevelClick(era_account) {
      var _this8 = this;
      this.dialogProject = true;
      PageIdCustomerLevelStatistics({
        stime: this.stime.toString(),
        etime: this.etime.toString(),
        era_account: era_account
      }).then(function (res) {
        _this8.tableDataLevel = res.data;
      });
    },
    dataDistribution: function dataDistribution(era_account) {
      var _this9 = this;
      this.dialogStatistics = true;
      pageIdDistributionStatistics({
        stime: this.stime.toString(),
        etime: this.etime.toString(),
        era_account: era_account
      }).then(function (res) {
        _this9.tableDataStatistics = res.data;
      });
    }
  }
};