import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { MyCustomerIndex, MyLoweIndex } from '@/api/ceshi_xiugai/whole';
import { ExtensionAnalysisList } from '@/api/ceshi_xiugai/popularize';
export default {
  name: 'DisplaySettingser',
  props: {
    displayArr: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    displayValue: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      value: JSON.parse(JSON.stringify(this.displayValue)),
      name: 'names',
      dialogTableVisible: false,
      draging: null,
      fullscreenLoading: false,
      page: 1,
      pagesize: 10,
      disp: [
      //全部客户、创建的客户、共享的客户、未回访的客户、操作下级-全部数据
      '序号', '推广账户简称', '推广平台', '运营方式', '创建人', '展示', '点击', '点击率', '消费', '返点', '账户现金消费', '名片数', '名片成本', '名片获取率', '录入量', '录入成本', '学员级别', '数据分配', '有效率', '撞库率', '成交量', '成交率', '收入', '消费费比', '现金费比', '账户余额', 'ROI']
    };
  },
  watch: {
    value: function value(newV) {
      this.$emit('update:displayValue', newV);
    },
    dialogTableVisible: function dialogTableVisible(newV) {
      var _this = this;
      if (!newV) {
        ExtensionAnalysisList({
          page: String(this.page),
          pagesize: String(this.pagesize),
          stime: '1629907200',
          etime: '1632585600',
          era_account: '1707036251116552',
          field_list: this.value
        }).then(function (respomse) {
          localStorage.setItem(_this.name, JSON.stringify(respomse.data.fields));
        });
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.value = JSON.parse(localStorage.getItem(this.name)) || this.displayValue;
  },
  methods: {
    handleClose: function handleClose(tag) {
      this.value.splice(this.value.indexOf(tag), 1);
    },
    reduction: function reduction() {
      this.value = JSON.parse(JSON.stringify(this.disp));
    },
    showEswt: function showEswt() {
      var _this2 = this;
      this.fullscreenLoading = true;
      ExtensionAnalysisList({
        page: String(this.page),
        pagesize: String(this.pagesize),
        stime: '1629907200',
        etime: '1632585600',
        era_account: '1707036251116552'
      }).then(function (respomse) {
        _this2.value = respomse.data.fields;
        _this2.dialogTableVisible = true;
        _this2.fullscreenLoading = false;
      });
    },
    // 开始拖放
    ondragstart: function ondragstart(event) {
      event.dataTransfer.setData('te', event.target.innerText); //设置拖放数据与类型，不能使用text，firefox会打开新tab
      this.draging = event.target;
    },
    ondragover: function ondragover(event) {
      event.preventDefault();
      var target = event.target;
      // 判断是不是span在进行拖动，防止将整个拖放容器拖动
      if (target.nodeName === 'SPAN' && target !== this.draging) {
        if (this._index(this.draging) < this._index(target)) {
          target.parentNode.insertBefore(this.draging, target.nextSibling);
        } else {
          target.parentNode.insertBefore(this.draging, target);
        }
      }
    },
    // 拖放结束刷新列表
    ondragend: function ondragend(event) {
      var nodeData = [];
      var target = event.target;
      var nodeArr = target.parentNode.children;
      // 获取排序后的数据
      if (target.nodeName === 'SPAN') {
        for (var i = 0; i < nodeArr.length; i++) {
          nodeData.push(nodeArr[i].innerText);
        }
        this.value = nodeData;
      }
    },
    // 拖放时进行排序
    _index: function _index(el) {
      var index = 0;
      if (!el || !el.parentNode) {
        return -1;
      }
      while (el && (el = el.previousElementSibling)) {
        index++;
      }
      return index;
    }
  }
};